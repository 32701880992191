import React, { useEffect, useState } from 'react';
import '../css/stuff.css';
import pers1 from './images/pers1.svg';
import pers2 from './images/pers2.svg';
import pers3 from './images/pers3.svg';
import pers4 from './images/pers4.svg';
import Select from 'react-select';

const Stuff = () => {
  // eslint-disable-next-line no-sparse-arrays

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const contacts = [
    {
      subdivision: 'Дилерский отдел',
      stuff: [
        {
          name: 'Болдырев Артём Геннадиевич',
          title: 'заместитель директора по продажам',
          mail: 'boldyrev@ok-schtern.ru',
          phone: '+7 905-682-14-74',
          img: pers2
        },

        // {
        //   name: 'Касимова Марина Альбертовна',
        //   title: 'менеджер',
        //   mail: 'zakaz1@ok-schtern.ru',
        //   phone: '+7 920-240-14-30',
        //   img: pers1
        // }
      ]
    },
    {
      subdivision: 'Корпоративный отдел',
      stuff: [
        {
          name: 'Герасимов Антон Эдуардович',
          title: 'менеджер',
          mail: 'gerasimov@ok-schtern.ru',
          phone: '+7 920-525-21-11',
          img: pers3
        }
      ]
    },
    {
      subdivision: 'Коммерческий отдел',
      stuff: [
        {
          name: 'Юров Сергей Викторович',
          title: 'заместитель директора по продажам',
          mail: 'yurov_s_v@mail.ru',
          phone: '+7 961-602-12-52',
          img: pers2
        },
        {
          name: 'Сестреживитовская Анжелика Александровна',
          title: 'начальник ПТО',
          mail: 'angel.lu@mail.ru',
          phone: '+7 903-864-05-79',
          img: pers1
        },
        {
          name: 'Гришанова Татьяна Николаевна',
          title: 'менеджер сервисной службы',
          mail: '	gr.tatyana@list.ru',
          phone: '+7 961-602-12-46',
          img: pers4
        },
        {
          name: 'Двойничева Екатерина Витальевна',
          title: 'менеджер по продажам',
          mail: '89513063821@mail.ru',
          phone: '+7 961-602-12-46',
          img: pers3
        }
      ]
    },
    {
      subdivision: 'Розничный отдел',
      stuff: [
        {
          name: 'Ушакова Елена Анатольевна',
          title: 'менеджер',
          mail: 'shop@ok-schtern.ru',
          phone: '+7 800-550-58-78',
          img: pers1
        }
      ]
    },
    {
      subdivision: 'Офис продаж магазин "Добрострой"',
      stuff: [
        // {
        //   name: 'Кутищева Оксана Александровна',
        //   title: 'менеджер',
        //   mail: 'dobro@ok-schtern.ru',
        //   phone: '+7 (4742)-20-33-75',
        //   img: pers4
        // },
        {
          name: 'Пожидаева Марина Васильевна',
          title: 'менеджер',
          mail: 'dobro@ok-schtern.ru',
          phone: '+7 (4742)-20-33-75',
          img: pers1
        }
      ]
    },
    {
      subdivision: 'Производство профильных систем Schtern',
      stuff: [
        {
          name: 'Прокофьев Михаил Юрьевич',
          title: 'руководитель',
          mail: 'prokofiev@knipping.ru',
          img: pers3
        }
      ]
    }
  ];

  const [select, setSelect] = useState(0);

  return (
    <div className="stuff">
      <div className="buttons-stuff">
        {contacts.map((button, index) => (
          <div
            className={select === index ? 'button-stuff-selected' : 'button-stuff'}
            onClick={() => setSelect(index)}>
            {button.subdivision}
          </div>
        ))}
      </div>

      <div className="person-stuff">
        {contacts[select].stuff.map((person) => (
          <div className="info-container-stuff">
            <p className="name-stuff">{person.name}</p>
            <p>{person.title}</p>
            {person.phone ? <p className="phone-stuff">Телефон:</p> : null}

            <p>{person.phone}</p>
            <p className="email-stuff">{person.phone === '+7(920)503-34-25' ? '' : 'Email:'}</p>
            <p>{person.mail}</p>
            <img src={person.img} alt="img" className="person-stuff__img" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stuff;
