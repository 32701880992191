import React from 'react';
import PlasticWindowBlock from "./PlasticWindow";
import PageHeader from "../../ui/PageHeader";
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import Component13 from '../../components/Elements/mainPage/Component13.jsx';
import UpperMenu from "../../components/Elements/UpperMenu.jsx";
import headerImage from './images/pageheadreImage.svg'
import mobileHeaderImage from './images/mobileImage.svg'
import ReviewsBlock from "./Reviews";

const Reviews = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <React.Fragment>
            <UpperMenu />
            <Stack sx={{
                paddingLeft: isMobile ? 0 : 10,
                paddingRight: isMobile ? 0 : 10,
                marginTop: isMobile ? 7 : 12,
                backgroundColor: '#ffffff'
            }}>
                <PageHeader mobileImage={mobileHeaderImage} image={headerImage}/>
                {/*<PlasticWindowBlock/>*/}
                <ReviewsBlock/>
            </Stack>
            <Component13/>
        </React.Fragment>
    );
};

export default Reviews;
