import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './components/Home';
import UpperMenu from './components/Elements/UpperMenu';
import BurgerMenu from './components/Elements/BurgerMenu';
import Dilleram from './components/Dilleram';
import KalkulyatorOkon from './components/Kalkulyator-okon';
import Kontakty from './components/Kontakty';
import GdeKupit from './components/Gde-kupit';
import BespltnyZamer from './components/BesplatnyZamer';
import BurgerMenuRS from './components/Elements/BurgerMenuRS';
import MainPage from './components/Elements/mainPage/MainPage.jsx';
import Katalog from './components/pages/katalog/Katalog.jsx';
import TMPage from './components/TMPage.jsx';
import PlastikovyeOkna from './components/pages/pvh-okna/PlastikovyeOkna.jsx';
import PlastikovyeOkna_mob from './components/pages/pvh-okna/PlastikovyeOkna_mob.jsx';
import ONas from './components/pages/o-nas/ONas.jsx';
import Istoriya from './components/pages/o-nas/Istoriya.jsx';
import OKompanii from './components/pages/o-nas/OKompanii.jsx';
import ContactForm from './components/contact-form/ContactForm.jsx';
import BurgerMenuNew from './components/Elements/burger-menu/BurgerMenuNew.jsx';
import Partneram from './components/pages/partneram/Partneram.jsx';
import Odnokamernye_sp from './components/pages/pvh-okna/odnokamernye-sp/Odnokamernye_sp.jsx';
import DillirForm from './components/contact-diller/DillerForm.jsx';
import BuyProfile from './components/buy-profile/BuyProfile.jsx';
import Ustanovka from './components/pages/ustanovka/Ustanovka.jsx';
import Otkosy from './components/pages/otkosy/Otkosy.jsx';
import Dostavka from './components/pages/dostavka/Dostavka.jsx';
import Galery from './components/pages/galery/Galery.jsx';
import GetJSON from './GetJSON.jsx';
import Profiles from './components/pages/profiles/index.jsx'
import Odnostvorchatye from "./pages/OdnostvorchatyeOkna/Odnostvorchatye";
import Dvystvorchatye from "./pages/DvustvorchatyeOkna/Dvystvorchatye";
import Trehstvorchatye from "./pages/TrehstvorchatyeOkna/Trehstvorchatye";
import DvuhkamernyePakety from "./pages/DvuhkamernyePakety/DvuhkamernyePakety";
import Balkony from "./pages/Balkony/Balkony";
import PlasticWindows from "./pages/plastic-windows";
import Balconies from "./pages/balkons";
import Reviews from "./pages/reviews";

const VerificationPage = lazy(() => import('./pages/VerificationPage'));
const DilerPage = lazy(() => import('./pages/DilerProfile'));




function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />}></Route>
      <Route path="/besplatny-zamer" element={<BespltnyZamer />}></Route>
      <Route path="/gde-kupit" element={<GdeKupit />}></Route>
      <Route path="/Kontakty" element={<Kontakty />}></Route>
      <Route path="/kalkulyator-okon" element={<KalkulyatorOkon />}></Route>
      <Route path="/uppermenu" element={<UpperMenu />}></Route>
      <Route path="/burgermenu" element={<BurgerMenu />}></Route>
      <Route path="/dilleram" element={<Dilleram />}></Route>
      <Route path="/burgerrs" element={<BurgerMenuRS />}></Route>
      <Route path="/aksessuary" element={<Katalog />}></Route>
      <Route path="/not-found" element={<TMPage />}></Route>

      {/* <Route path="/main-menu" element={<MainMenu />}></Route> */}
      <Route path="*" element={<Navigate to="/not-found" />} />
      {/*<Route path="/plastikovye-okna" element={<PlastikovyeOkna />}></Route>*/}

      <Route path="/po" element={<PlastikovyeOkna_mob />}></Route>
      <Route path="/o-nas" element={<ONas />}></Route>
      <Route path="/istoriya" element={<Istoriya />}></Route>
      <Route path="/o-kompanii" element={<OKompanii />}></Route>
      <Route path="/contact-form" element={<ContactForm />}></Route>
      <Route path="/test-menu" element={<BurgerMenuNew />}></Route>
      <Route path="/partneram" element={<Partneram />}></Route>
      <Route path="/odnokamernye-steklopakety" element={<Odnokamernye_sp />}></Route>
      <Route path="/stat-dillerom" element={<DillirForm />}></Route>
      <Route path="/kupit-profil" element={<BuyProfile />}></Route>
      <Route path="/ustanovka" element={<Ustanovka />}></Route>
      <Route path="/otkosy" element={<Otkosy />}></Route>
      <Route path="/dostavka" element={<Dostavka />}></Route>
      <Route path="/galery" element={<Galery />}></Route>
      <Route path="/profiles" element={<Profiles />}></Route>
      {/* <Route path="/get-json" element={<GetJSON />}></Route> */}
        <Route path="/odnostvorchatye-okna" element={<Odnostvorchatye />}></Route>
        <Route path="/dvustvorchatye-okna" element={<Dvystvorchatye />}></Route>
        <Route path="/trehostvorchatye-okna" element={<Trehstvorchatye />}></Route>
        <Route path="/dvuhkamernye-steklopakety" element={<DvuhkamernyePakety />}></Route>
        <Route path="/balkony" element={<Balkony/>}></Route>
        <Route path="/verification" element={<VerificationPage/>}></Route>
        <Route path="/diller" element={<DilerPage/>}></Route>
        <Route path="/plastikovye-okna" element={<PlasticWindows/>}></Route>
        <Route path="/balconies" element={<Balconies/>}></Route>
        <Route path="/reviews" element={<Reviews/>}></Route>
    </Routes>
  );
}

export default App;
