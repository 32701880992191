    import React, { useState, startTransition } from 'react';
    import {
        Box,
        Typography,
        Card,
        CardMedia,
        CardContent,
        Collapse,
        Grid,
        Paper,
        Stack,
        useTheme,
        useMediaQuery
    } from '@mui/material';
    import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
    import ExpandLessIcon from '@mui/icons-material/ExpandLess';
    import Phone from "../../assest/icons/Phone";
    import ContactUs from "../../assest/icons/ContactUs";
    import Mail from "../../assest/icons/Mail";
    import styles from './PlasticWindowBlock.module.css';
    import balkon_main from './images/balkon_maon.webp';
    import slide_balkon from './images/sample (1).jpeg'
    import warm_balkon from './images/sample.jpeg'
    import balkon from './images/balkon.png'


    import {createTheme, ThemeProvider} from "@mui/material/styles";

    const windowTypes = [
        {
            title: '',
            description: 'Балкон – это важный элемент архитектуры, который может стать уютным уголком для отдыха. Правильное остекление балкона или лоджии увеличивает полезное пространство и создает комфортную атмосферу. Выбор между теплым, распашным или раздвижным остеклением зависит от индивидуальных предпочтений и особенностей помещения. Учитывая все доступные решения, можно создать пространство, которое будет радовать круглый год, независимо от погодных условий.',
            image: balkon_main,
        },
        {
            title: 'Теплое остекление балконов и лоджий',
            description: 'Теплое остекление является идеальным способом создать комфортное и уютное пространство на балконе или лоджии, что особенно актуально в условиях переменчивого климата. Использование энергосберегающих стеклопакетов позволяет значительно снизить теплопотери, обеспечивая оптимальные условия для проживания и отдыха в любое время года. Такой подход не только эффективно защищает от холодного воздуха и атмосферных воздействий, но и существенно уменьшает уровень шума с улицы, создавая тем самым комфортную и спокойную атмосферу внутри.\n' +
                'Однако важно понимать, что теплое остекление – это не только установка окон. Для достижения максимального эффекта необходимо также утеплить стены и полы. Это позволит избежать возникновения холодных зон и гарантирует, что балкон или лоджия будут служить комфортным продолжением жилого пространства. Утепление стен и пола не только улучшает теплоизоляцию, но и защищает от конденсата и плесени, что способствует созданию здорового микроклимата. Таким образом, теплое остекление открывает новые возможности для использования балкона, превращая его в полноценную часть вашего дома, которая может стать местом для отдыха, работы или даже зимнего сада.\n',
            image: warm_balkon,
        },
        {
            title: 'Остекление с раздвижными створками',
            description: 'Остекление с раздвижными створками — это эффективное решение для современных балконов и лоджий, обеспечивающее оптимальное использование пространства. Основное преимущество этого типа остекления заключается в том, что створки перемещаются вдоль рамы, не требуя дополнительного места для открывания. Это делает их идеальным выбором для небольших площадей, где важно сохранить свободное пространство. Раздвижные окна обеспечивают лёгкий доступ к свежему воздуху и естественному свету, что способствует созданию комфортной атмосферы. Кроме того, такие конструкции обычно обладают высокой надежностью и долговечностью, что делает их популярными среди владельцев жилья, желающих получить долговечное и функциональное решение.',
            image: slide_balkon,
        },
        {
            title: 'Распашное остекление балконов и лоджий',
            description: 'Распашное остекление — это классический и проверенный временем способ остекления балконов и лоджий. Открывающиеся створки обеспечивают отличный доступ к свежему воздуху, однако для их полного открытия требуется дополнительное пространство, что может быть недостатком в ограниченных условиях. Тем не менее, распашные окна обеспечивают хорошую вентиляцию, особенно в больших проемах, и позволяют легко очищать стекла. Этот вариант подходит для тех, кто ценит простоту в эксплуатации и лёгкость ухода. Распашные окна также предлагают множество стилей и отделок, что позволяет гармонично интегрировать их в любой интерьер, добавляя эстетическую привлекательность и функциональность.',
            image: balkon,
        },
    ];

    const contactInfo = [
        { label: 'Телефон', value: '8-800-550-58-78', icon: <Phone color={'#222222'} /> },
        { label: 'Email', value: 'shop@ok-schtern.ru', icon: <Mail color={'#222222'} /> },
        { label: 'Форма обратной связи', value: 'Заполнить', icon: <ContactUs color={'#222222'} /> },
    ];

    const PlasticWindowBlock = () => {
        const [expandedIndex, setExpandedIndex] = useState(null);
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));

        const handleExpandClick = (index) => {
            startTransition(() => {
                setExpandedIndex(expandedIndex === index ? null : index);
            });
        };

        const th = createTheme({
            typography: {
                h5: {
                    fontSize: '24px',
                    '@media (max-width:600px)': {
                        fontSize: '20px',
                    },
                },
                h6: {
                    fontSize: '20px',
                    '@media (max-width:600px)': {
                        fontSize: '18px',
                    },
                },
                subtitle1: {
                    fontSize: '18px',
                    '@media (max-width:600px)': {
                        fontSize: '16px',
                    },
                },
                body1: {
                    fontSize: '16px',
                    '@media (max-width:600px)': {
                        fontSize: '14px',
                    },
                },
                body2: {
                    fontSize: '14px',
                    '@media (max-width:600px)': {
                        fontSize: '12px',
                    },
                },
            },
        });

        return (
            <ThemeProvider theme={th}>


                <Box sx={{ padding: 3 }}>
                    <Grid container spacing={2}>
                        {isMobile &&
                            <Grid item xs={12} md={3}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                                    Свяжитесь с нами
                                </Typography>
                                <Paper sx={{ padding: 2, borderRadius: 4 }}>
                                    <Stack gap={2}>
                                        {contactInfo.map((info, index) => (
                                            <Box key={index}>
                                                <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                                                    <Stack>{info.icon}</Stack>
                                                    <Stack gap={0.5}>
                                                        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>{info.label}:</Typography>
                                                        {info.label === 'Телефон' ? (
                                                            <Typography
                                                                variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}
                                                                onClick={() => window.open(`tel:${info.value}`)}
                                                            >
                                                                {info.value}
                                                            </Typography>
                                                        ) : info.label === 'Email' ? (
                                                            <Typography
                                                                variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}
                                                                onClick={() => window.open(`mailto:${info.value}`)}
                                                            >
                                                                {info.value}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                                                {info.value}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Paper>
                            </Grid>
                        }
                        <Grid item xs={12} md={9}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                               Остекление балконов
                            </Typography>
                            <Paper sx={{ borderRadius: 4 }}>
                                <Stack direction={{ xs: 'column', md: 'row' }} sx={{ alignItems: 'stretch', minHeight: '100%' }}>
                                    <Box sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                                        <CardMedia
                                            component="img"
                                            image={windowTypes[0].image}
                                            alt="Одностворчатые окна"
                                            sx={{
                                                borderRadius: 4,
                                                maxHeight: 400,
                                                maxWidth: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Box>
                                    <Stack sx={{ padding: 4, width: { xs: '100%', md: '50%' }, justifyContent: 'center' }} gap={1}>
                                        {
                                            !isMobile ?
                                                <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                                    {windowTypes[0].title}
                                                </Typography>
                                                :
                                                <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                                    {windowTypes[0].title}
                                                </Typography>
                                        }
                                        <Typography variant="body1" className={styles.title_text} sx={{ textAlign: 'justify', wordBreak: 'break-word', fontFamily: 'Unbounded' }}>
                                            {windowTypes[0].description}
                                        </Typography>
                                        <Typography variant="body2" sx={{ textAlign: 'justify', wordBreak: 'break-word', fontFamily: 'Unbounded' }}>
                                            {windowTypes[0].usage}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>

                        {!isMobile &&
                            <Grid item xs={12} md={3}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                                    Свяжитесь с нами
                                </Typography>
                                <Paper sx={{ padding: 2, borderRadius: 4 }}>
                                    <Stack gap={2}>
                                        {contactInfo.map((info, index) => (
                                            <Box key={index}>
                                                <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                                                    <Stack>{info.icon}</Stack>
                                                    <Stack gap={0.5}>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>{info.label}:</Typography>
                                                        {info.label === 'Телефон' ? (
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}
                                                                onClick={() => window.open(`tel:${info.value}`)}
                                                            >
                                                                {info.value}
                                                            </Typography>
                                                        ) : info.label === 'Email' ? (
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}
                                                                onClick={() => window.open(`mailto:${info.value}`)}
                                                            >
                                                                {info.value}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}>
                                                                {info.value}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Paper>
                            </Grid>
                        }

                        {windowTypes.slice(1).map((window, index) => (
                            <Grid item xs={12} md={4} key={window.title}>
                                <Card sx={{ borderRadius: 4, padding: 2 }}>
                                    <CardMedia
                                        component="img"
                                        // height="200"
                                        image={window.image}
                                        alt={window.title}
                                        sx={{
                                            borderRadius: 4,
                                            maxHeight: 300,
                                            maxWidth: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <CardContent sx={{ padding: 2 }}>
                                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                            {window.title}
                                        </Typography>
                                        <Stack sx={{ cursor: "pointer", width: "max-content", alignItems: "center" }} direction={'row'} onClick={() => handleExpandClick(index)}>
                                            <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                                {expandedIndex === index ? 'Свернуть' : 'Подробнее'}
                                            </Typography>
                                        </Stack>

                                        <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                            <Box sx={{ paddingTop: 2 }}>
                                                <Typography variant="body2" sx={{ fontFamily: 'Unbounded', textAlign: 'justify', wordBreak: 'break-word' }}>
                                                    {window.description}
                                                </Typography>
                                            </Box>
                                        </Collapse>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    };

    export default PlasticWindowBlock;
