import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Modal,
    Rating,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import axios from 'axios';

const ReviewsBlock = () => {
    const [open, setOpen] = useState(false);
    const [newReviewText, setNewReviewText] = useState('');
    const [newReviewAuthor, setNewReviewAuthor] = useState('');
    const [newRating, setNewRating] = useState(5);
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewReviewText('');
        setNewReviewAuthor('');
        setNewRating(5);
    };

    const handleTextChange = (event) => setNewReviewText(event.target.value);
    const handleAuthorChange = (event) => setNewReviewAuthor(event.target.value);
    const handleRatingChange = (event, newValue) => setNewRating(newValue);

    const getReviews = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get('https://schtern-mail.ru/api/reviews');
            setIsLoading(false)
            setReviews(response.data);
        } catch (error) {
            setIsLoading(false)
            console.error('Ошибка при получении отзывов:', error);
        }
    };

    const createReview = async () => {
        if (!newReviewText.trim() || !newReviewAuthor.trim()) {
            alert('Пожалуйста, заполните все поля!');
            return;
        }

        const newReview = {
            text: newReviewText,
            rating: newRating,
            author: newReviewAuthor,
        };

        try {
            const response = await axios.post('https://schtern-mail.ru/api/reviews', newReview);
            setReviews((prevReviews) => [response.data, ...prevReviews]);
            handleClose();
        } catch (error) {
            console.error('Ошибка при создании отзыва:', error);
        }
    };

    useEffect(() => {
        getReviews();
    }, []);

    const theme = createTheme({
        typography: {
            subtitle1: {fontSize: '18px', fontFamily: 'Unbounded'},
            subtitle2: {fontSize: '14px', fontFamily: 'Unbounded'},
            h5: {fontSize: '22px', fontFamily: 'Unbounded'},
            body1: {fontSize: '12px', fontFamily: 'Unbounded'},
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{padding: 3, backgroundColor: '#ffffff'}}>
                <Stack
                    direction={{xs: 'column', sm: 'row'}}
                    justifyContent="space-between"
                    alignItems={{xs: 'start', sm: 'center'}}
                    sx={{width: '100%', marginBottom: 2}}
                >
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                        Отзывы
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        sx={{
                            mt: 0,
                            fontFamily: 'Unbounded',
                            borderRadius: 5,
                            fontSize: {
                                xs: '12px',
                                sm: '10px',
                                md: '12px',
                                lg: '14px',

                            },
                            padding: { xs: '6px 12px', sm: '8px 16px' }
                        }}
                    >
                        Оставить отзыв
                    </Button>
                </Stack>
                {isLoading ? (
                    <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '7rem' }}>
                        <CircularProgress size={40} />
                    </Stack>
                ) : reviews?.length > 0 ? (
                    <Grid container spacing={2}>
                        {reviews.map((review) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={review._id}>
                                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 4 }}>
                                    <CardContent sx={{ flex: '1 1 auto', bgcolor: '#f5f5f5', padding: 3 }}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                            {review.author}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 2, color: '#555' }}>
                                            {review.text}
                                        </Typography>
                                        <Rating value={review.rating} readOnly sx={{ fontSize: '1.5rem' }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Отзывы отсутствуют
                    </Typography>
                )}

                <Modal open={open} onClose={handleClose}>
                    <Box sx={{
                        width: { xs: '90%', sm: 600 },
                        mx: 'auto',
                        mt: '10%',
                        p: 3,
                        bgcolor: 'background.paper',
                        borderRadius: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: 600,
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2, fontSize: { xs: '16px', sm: '18px' } }}>
                            Оставить отзыв
                        </Typography>
                        <TextField
                            label="Ваше имя"
                            variant="outlined"
                            fullWidth
                            value={newReviewAuthor}
                            onChange={handleAuthorChange}
                            sx={{
                                marginBottom: 2,
                                '& .MuiInputBase-root': { fontSize: { xs: '14px', sm: '16px' } },
                            }}
                        />
                        <TextField
                            label="Ваш отзыв"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={newReviewText}
                            onChange={handleTextChange}
                            sx={{
                                marginBottom: 2,
                                '& .MuiInputBase-root': { fontSize: { xs: '14px', sm: '16px' } },
                            }}
                        />
                        <Stack
                            direction='column'
                            sx={{
                                display: 'flex',
                                width: '100%',
                        }}>
                            <Typography variant="subtitle1" sx={{ fontSize: { xs: '14px', sm: '16px' }, mb: 1 }}>
                                Оценка
                            </Typography>
                            <Rating
                                value={newRating}
                                onChange={handleRatingChange}
                                sx={{
                                    marginBottom: 2,
                                    fontSize: { xs: '4.5rem', sm: '3rem', md: '2.5rem', lg: '2rem' },
                                }}
                            />
                        </Stack>
                        <Box sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            gap: 1,
                        }}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                sx={{
                                    borderRadius: 5,
                                    fontSize: { xs: '12px', sm: '14px' },
                                    padding: { xs: '6px 12px', sm: '8px 16px' },
                                }}
                            >
                                Закрыть
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={createReview}
                                sx={{
                                    borderRadius: 5,
                                    fontSize: { xs: '12px', sm: '14px' },
                                    padding: { xs: '6px 12px', sm: '8px 16px' },
                                }}
                            >
                                Отправить
                            </Button>
                        </Box>
                    </Box>
                </Modal>


            </Box>
        </ThemeProvider>
    );
};

export default ReviewsBlock;
